import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {cartTotal} from '../action/commonAction'
import utils from '../utils/utils'

class SearchBar extends Component {
    constructor(props){
      super(props)
      this.state = {searchTerm : "",errors : {}, cart:0}
      this.onChange = this.onChange.bind(this);
      this.loadCart = this.loadCart.bind(this);
    }

    loadCart(e) {
      this.props.history.push('/cart')
    }

    onChange(e) {
      this.setState({ [e.target.name] : e.target.value});
    }

    componentWillMount = ()=> {
      this._asyncRequest = cartTotal().then(
        externalData => {
          this._asyncRequest = null;
          //Update cart count.
          var totalItem=0
          externalData.data.cart.forEach(element => {
            totalItem = totalItem + element.quantity
          });
          this.setState({cart:totalItem});
        }
      );
    }

    componentWillUnmount() {
      if (this._asyncRequest) {
        this._asyncRequest.cancel();
      }
    }

    componentWillReceiveProps = nextProps => {
      if(nextProps.common.totalItem != null){
        this.setState({cart:nextProps.common.totalItem});
      }
    }

    render(){
      // font-style: oblique;
      // font-weight: bolder;
      // font-size: x-large;
      // font-family: cursive;
      // font-variant-caps: titling-caps;
      console.log(window.location.pathname);
      return (
        <div className="row justify-content-between " style={{padding:'10px', background:'white', boxShadow:'0 4px 8px 0 rgba(28,32,36,.2)'}}>          
          <div className="col col-lg-2 col-5 col-md-2 align-self-center" style={{textAlign:'right'}}>
            <div id="header-logo">
              {/* <a href="/" title="Nutri Newron" rel="home">
                <img src="/img/logo.png" alt="Nutri Newron" className="logo-img logo-img-xs"/>
              </a> */}
              <span className="input-group-btn">
              {new utils().isCartEnabled(this.props.location) && <a className="nav-link" style={{paddingTop:'1rem', color:'rgba(0,0,0,.5)', textAlign:'end'}} 
                 href={window.location.pathname == "/" ? "/products" : "/" } >
                  <button className="btn-sitewide btn btn-default" type="button">
                    <b>   {window.location.pathname == "/" ? "Products" : "Home" } </b>
                  </button>
                </a>}
              </span>
            </div>
          </div>

          <div className="col col-lg-2 col-4 col-md-2 align-self-center">
            {<span className="input-group-btn" style={{paddingLeft:'10px'}}>
                <button className="btn-sitewide btn btn-default" type="button" onClick={this.loadCart}>
                  <b>Cart - {this.state.cart}</b>
                </button>
              </span>}
          </div>  
        </div>
      )
    }
  }

  function mapStateToProps(state){
    //console.log(state)
    return {
      common:state.common,
    };
  }

  export default withRouter(connect(mapStateToProps, {})(SearchBar));