import React, {Component}from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {handleLogin} from '../../../action/loginAction'
import {handleCreateAccountOrUpdatePassword} from '../../../action/createAccountOrUpdatePassword'
import AlertBar from '../../Alertbar'


class LoginPage extends Component {
    constructor(props){
        super(props);
        this.state = {};
        this.handleLogin = this.handleLogin.bind(this);
        this.handleCreateAccountOrUpdatePassword = this.handleCreateAccountOrUpdatePassword.bind(this);
    }
    
    componentWillMount(){
        // this.props.initalLoad();
    }

    componentWillReceiveProps(nextProps){
        console.log('componentWillReceiveProps')
        this.setState(nextProps.login);
    }

    validateUserId(userId) 
    {

        let  mailFormat =  /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/
        // const phoneNumberFormat = "^([0-9]{10})$";

        if (!(userId.match(mailFormat)))
           console.log("Mail format did not match")
        
        // if (!(userId.match(phoneNumberFormat)))
        //    console.log("Phone format did not match")

        // if ((userId.match(mailFormat)) || userId.match(phoneNumberFormat))
        if (userId.match(mailFormat))
        {
            return true;
        }
        else 
             return false;
    }

    handleLogin(e){
        // Prevent default behavior
        e.preventDefault();
        console.log('handleLogin')

        const data = new FormData(e.target);
    
        var userid=data.get('userid');

        if (!this.validateUserId(userid)){
          alert("Please enter email id")
          return;
        }

        var password=data.get('password');
        console.log("userid = " + userid + "Password = " + password);
        console.log("who submitted ?")
        console.log(e.nativeEvent.submitter.name);
        if (e.nativeEvent.submitter.name == "Register")
        {
            if (password.length > 0)
            {
                alert("You have eterred a passwor which will be  ignored. Password is sent to your phone/email")
            }    
            this.props.handleCreateAccountOrUpdatePassword({"userid":userid,"password":password},this.props.history)
        }
        else 
        {
            if ((!password) || (password.length != 4))
            {
                alert("Please enter valid password")
                return;
            }    
            this.props.handleLogin({"userid":userid,"password":password},this.props.history)
        }
    }

    handleCreateAccountOrUpdatePassword(e){
        // Prevent default behavior
        e.preventDefault();
        console.log('In handleCreatePassword');
        // const data = new FormData(e.target);
    
        // var userid=data.get('userid');
        // var password=data.get('password');
        
        // console.log(e.nativeEvent.submitter.name);
        // this.props.handleCreateAccountOrUpdatePassword({"userid":userid,"password":password},this.props.history)
        
    }
    

    render () {   
    return (
        <div className="container-fluid">
    <span>
               <br/><br/>
               <div className="row justify-content-center">
                   <div className="col col-md-6 col-11">
                       <div className="row">
                       <div className="col col-12" style={{textAlign:'left', boxShadow: '0 4px 8px 0 rgba(28,32,36,.2)'}}>
                       <AlertBar/>
                        <form onSubmit={this.handleLogin}>
                            <input type="text" name="userid" placeholder="Email Id" style={{minWidth:'90%'}} required maxLength='80'/><br/><br/>
                            <input type="password" name="password" placeholder="Password" style={{minWidth:'90%'}} maxLength='10'/><br/><br/>
                            <div style={{minWidth:'50%', textAlign:'left'}}>
                                <input type="submit" name="Login" className="btn-sitewide btn btn-default" value="Login" style={{width:'90%'}}/>
                            </div>
                            <br/>
                            <button type="submit" name="Register" className="btn-sitewide btn btn-default" style={{minWidth:'90%'}}>
                                    To Register (or forgot passowrd) 
                            </button>                
                        </form>
                        <br/>
                    </div>
                       </div>
                   </div>
               </div>
        </span>  
        </div>             
        )
    }
    
}


function mapStateToProps(state){
    return {login:state.login};
}

// export default  connect(mapStateToProps,{handleLogin, handleCreateAccountOrUpdatePassword})(withRouter(LoginPage));

export default withRouter(connect(mapStateToProps, {handleLogin, handleCreateAccountOrUpdatePassword})(LoginPage));


                
  