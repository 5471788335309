import axios from 'axios'
import localStorage from '../utils/localStorageService' 

export const handleCreateAccountOrUpdatePassword = (data, history) => async dispatch => {
    await axios.post('/api/a/createAccountOrUpdatePassword', data, { validateStatus: false})
    .then(async resp => {
        console.log(resp.status)
        console.log(resp);
        if(resp.status === 200){
            console.log("Registration or password update done");

            return
        }else if(resp.status === 404){
            return dispatch({
                type:'ERR_ALERT',
                payload:{alertMsg:resp.data.err}
              }); 
        } else if ( (resp.status > 201)){
            
            return dispatch({
                type:'ERR_ALERT',
                payload:{alertMsg:resp.data.err}
              }); 
        } else{
            return dispatch({
                type:'ERR_ALERT',
                payload:{alertMsg:'Opps..!! Something went wrong.'}
              });
        }
    }).catch(err => {
        console.log(err)
        return dispatch({
            type:'ERR_ALERT',
            payload:{alertMsg:'Opps..!! Something went wrong.'}
        });
    })
}