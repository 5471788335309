import axios from 'axios'
import localStorageLib from '../utils/localStorageService' 

export const handleLogin = (data, history) => async dispatch => {
    await axios.post('/api/a/checkLogin', data, { validateStatus: false})
    .then(async resp => {
        console.log("login respponse =");
        console.log(resp);

        if((resp.status === 200) || (resp.status === 201)){
            console.log("login sucessfull")
            console.log ("Response data is...");
            console.log(resp.data);
            localStorageLib.setToken(resp.data);
            history.push('/products')   
            return
        }else if ( (resp.status > 201)){
            
            return dispatch({
                type:'ERR_ALERT',
                payload:{alertMsg:resp.data.err}
              }); 
        } else{
            return dispatch({
                type:'ERR_ALERT',
                payload:{alertMsg:'Opps..!! Something went wrong.'}
              });
        }
    }).catch(err => {
        console.log(err)
        return dispatch({
            type:'ERR_ALERT',
            payload:{alertMsg:'Opps..!! Something went wrong.'}
        });
    })
}