import React, {Component}from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadCartPage, updateCart, removeCartItem, uploadPrescription } from '../../../action/commonAction'
import Utils, {Currency} from '../../../utils/utils'


const PriceInfo = (data) => {
    return <div style={{verticalAlign:'top'}}>
        <span>
            {Currency}{new Utils().roundToTwo(data.priceInfo.eachItemPrice)} Each<br/>
            Total Amount: {Currency}{new Utils().roundToTwo(data.priceInfo.totalItemPrice)}<br/>
            {data.priceInfo.totalSaving===0?'':'Saving : {Currency}'+ new Utils().roundToTwo(data.priceInfo.totalSaving)}<br/>
            <b style={{color:'red'}}>{data.priceInfo.priceType==='ORIGINAL'?'':data.priceInfo.priceType}</b>
        </span>
    </div>
}

const Quantity = (data) => {
    var optionHtml = [...Array(31).keys()].map((ele,k)=>{
        if(ele===0){
            return
        }
        return <option key={k} value={ele}>{ele}</option>
    })
    return <div style={{verticalAlign:'middle'}}>
        {/* <label htmlFor="exampleFormControlSelect1" style={{textTransform: "capitalize"}}>Quantity : </label> */}
        <select className="form-control" id="exampleFormControlSelect1" style={{width:'50%', display:'inline'}} name={data.id} onChange={data.onselect} value={data.quantity}>
            {optionHtml}
        </select>
        <div style={{textAlign:'right', display:'inline', paddingLeft:'5px'}}>
            <input type="button" value="X" name={data.id} onClick={data.removeItem} className="btn-sitewide" />
        </div>
    </div>
}


class Cartpage extends Component {

    constructor(props){
        super(props)
        this.updateCart = this.updateCart.bind(this)
        this.removeItem = this.removeItem.bind(this)
        this.loadCheckout = this.loadCheckout.bind(this)
        this.onFileChange = this.onFileChange.bind(this)
        this.openProductPage = this.openProductPage.bind(this)
        this.state = {            
        }
    }

    openProductPage = (e) => {
        var sku = e.target.innerHTML
        console.log(sku)
        if(sku && sku.length === 11){
            var product = sku.match(/(.{1,7})/g);
            console.log(product)
            this.props.history.push('/p/cart/' + product[0])
        }
    }

    loadCheckout = (e) => {
        this.props.history.push('/checkout')
    }

    removeItem = (e) => {
        this.props.removeCartItem(e.target.name)
    }
    
    updateCart = (e) => {
        var skuUpdated = e.target.name
        var updatedQuantity = e.target.value
        this.props.updateCart(skuUpdated, updatedQuantity)
    }

    onFileChange = (e) => {
        this.props.uploadPrescription(e.target.files[0])
    }

    componentDidMount = ()=> {
        this._asyncRequest = loadCartPage(this.props.location).then(
            externalData => {
                this._asyncRequest = null;
                this.setState(externalData.data);
            }
        );
    }

    componentWillUnmount() {
        if (this._asyncRequest) {
          this._asyncRequest.cancel();
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.common.type === 'cart_details'){
            this.setState(nextProps.common.cart);
        }
    }

     render() {
        var isCartEmpty = Object.keys(this.state).length > 0 && this.state.ItemList && this.state.ItemList.length > 0;
        var prescriptionImg = Object.keys(this.state).length > 0 && this.state.PrescriptionDetails && this.state.PrescriptionDetails.uploaded && '/api/o/prescription/'+this.state.PrescriptionDetails.fileName;
        //console.log('isCartEmpty = ' + isCartEmpty)
       return(
           <div><br/><br/><br/>
                {isCartEmpty === false && <div>
                    <br/><br/>
                    <h3>Your Cart is Empty, Please continue Shopping <a href="/">Here</a></h3>
                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                    </div>}
                {isCartEmpty === true && <div className="row justify-content-center">
                        <ul className="list-unstyled col col-sm-10 col-12">
                            {this.state.ItemList.map((e, i)=>{
                                return <div className="row justify-content-center" style={{paddingBottom:'1%'}} key={i}>
                                    <div className="col col-sm-2 col-12">
                                        <img src={'/prdimg/'+e.itemInfo.productImage} alt={e.sku} style={{width:'100%'}}/>
                                    </div>
                                    {/* <div className="media-body" style={{textAlign:'justify'}}> */}
                                        <div className="col col-sm-3 col-12 cart-labels-sm cart-labels-xs" style={{verticalAlign:'top'}}>
                                            <h5>{e.itemInfo.name}</h5>
                                            {e.itemInfo.attributes && e.itemInfo.attributes.map((y, key) => {
                                            return <span key={key}>
                                                <span><b>{y.name}</b> : {y.value}</span><br/>
                                            </span>
                                            })}
                                            <span>
                                                <span style={{cursor:'pointer'}} onClick={this.openProductPage}>{e.sku}</span><br/>
                                            </span>
                                        </div>
                                        <div className="col col-sm-4 col-10 order-2 order-sm-1">
                                            <Quantity quantity={e.quantity} id={e.sku} onselect={this.updateCart} removeItem={this.removeItem}/> 
                                        </div>
                                        <div className="col col-sm-3 col-10 order-1 order-sm-2">
                                            <PriceInfo priceInfo={e.priceInfo}/>
                                        </div>
                                    {/* </div> */}
                                    <hr/>
                                </div>
                            })}
                            <hr/>
                            {/* <li className="media" style={{padding: '1px'}}> */}
                                <div className="row justify-content-center">
                                    <div className="col col-12 col-sm-4 offset-sm-9">
                                        Sub Total : {Currency}{new Utils().roundToTwo(this.state.PriceInfo.orderSubTotal)}<br/>
                                        {/* Total Saving : {Currency}{new Utils().roundToTwo(this.state.PriceInfo.orderItemSaving)}<br/> */}
                                        Order Total : {Currency}{new Utils().roundToTwo(this.state.PriceInfo.orderSubTotal)}<br/>
                                        <hr/>
                                    </div>
                                </div>
                            {/* </li> */}
                            <li className="media" style={{padding: '1px'}}>
                                <div className="media-body" style={{textAlign:'justify'}}>
                                    <div style={{display:'inline-block', verticalAlign:'middle', float:'right', minWidth:'300px'}}>
                                        {this.state.PrescriptionDetails && this.state.PrescriptionDetails.uploaded && 
                                        <img src={prescriptionImg} style={{maxWidth:'150px', maxHeight:'150px', minHeight:'150px', minWidth:'150px'}} alt='Prescription'/>
                                        }
                                        <br/>
                                        <label className="custom-file-upload " style={{boxShadow: '0 4px 8px 0 rgba(28,32,36,.2)'}}>
                                            <input type="file" accept="image/*" onChange={this.onFileChange} name="upload"/>
                                            Upload/Change Prescription Image
                                        </label>
                                    </div>
                                </div>
                            </li>
                            <li className="media" style={{padding: '1px'}}>
                                <div className="media-body" style={{textAlign:'justify'}}>
                                    <div style={{display:'inline-block', verticalAlign:'middle', float:'right', minWidth:'300px'}}>                                        
                                        <input type="button" value="Checkout" className="btn-sitewide" 
                                        style={{width: '70%', height: '40px'}}
                                        onClick={this.loadCheckout}/>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <br/><br/><br/><br/><br/><br/>
                        <b>Order : {this.state.Order_id}</b>
                    </div>
                    }
           </div>
       )
    }
}

function mapStateToProps(state){
    return {common:state.common};
}

export default connect(mapStateToProps,{updateCart, removeCartItem, uploadPrescription})(withRouter(Cartpage));
