import React, {Component}from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { initalLoad } from '../../../action/homeAction'
import LoginPage from './LoginPage'
import LocalStorageService from "../../../utils/localStorageService";
// import '/App.css';

class Homepage extends Component {

    constructor(props){
        super(props)
        this.state = {
            loggedIn:false,
            slots:[
            ]
        }
    }

    componentWillMount(){
        const localStorageService = LocalStorageService.getService();
        const token = localStorageService.getAccessToken();
        if (token) {
            this.state.loggedIn = true;
        }
        // this.props.initalLoad();
    }

    componentWillReceiveProps(nextProps){
        // this.setState(nextProps.home);
    }
     

     render() {
       return <span><br/>
                <div className="row" style={{background:"white", alignItems: "center", display: "flex"}}>
                      {(!this.state.loggedIn) &&       
                            <div className="col-sm-12 col-md-6" style={{textAlign:"left"}}>     
                                    <LoginPage/>
                            </div>
                      }
                  <div className= {(this.state.loggedIn) ? "col-sm-12 col-md-12" : "col-sm-12 col-md-6"}   >       
                        
                            <img src="/img/logo-natura.png" alt="" style={{width:'42%'}}/>
                                
                            <img src="/img/bnr_2.jpeg" alt="" style={{width:'36%', marginLeft:"30px"}}/>
                       
                    </div>
                </div>
            <br/><br/>
           </span>       
     }
}

function mapStateToProps(state){
    return {home:state.home};
  }


export default connect(mapStateToProps,{initalLoad})(withRouter(Homepage));
