import React, {Component} from 'react'

import Phoneicon from '../../svg/phoneicon.svg'
import Sendmailicon from '../../svg/send-mail.svg'
import CustomerServiceicon from '../../svg/customer-service.svg'
import Policyicon from '../../svg/policy.svg'
// import twittericon from '../../svg/twitter.svg'

class Sitefooter extends Component{
    render(){
        return(
            <div style={{paddingTop:'0px'}}>
               <div className="row justify-content-center" style={{background: '#666666'}}>
               <ul className="list-group list-group-horizontal-sm max-width-xs" style={{font:'normal 15px/19px Open Sans,arial,sans-serifcc', paddingTop: '02px', paddingBottom: '02px'}}>                   
                    <li className="list-group-item">
                        <a href="mailto:ravikar@nutrinewron.com" style={{color:'black', textDecoration: 'none'}}>
                            <img src={Sendmailicon} style={{width:'20px', height:'20px'}} alt=""/>
                            <span data-automation-id="help-sectiontext-block">
                                <span style={{display:'block'}}>Send us an email</span>
                                <strong data-automation-id="help-text-0" className="_3qnsk">ravikar@nutrinewron.com</strong>
                            </span>
                        </a>
                    </li>
                    <li className="list-group-item">
                        <a href="https://wa.me/+979606666356?text=Hi" target="_blank" style={{color:'black', textDecoration: 'none'}}>
                            <img src={CustomerServiceicon} style={{width:'20px', height:'20px'}} alt=""/>
                            <span data-automation-id="help-sectiontext-block">
                                <span style={{display:'block'}}>Need help?</span>
                                <strong data-automation-id="help-text-0" className="_3qnsk">WhatsApp us</strong>
                            </span>
                        </a>
                    </li>
                    <li className="list-group-item">
                        
                            <img src={Policyicon} style={{width:'20px', height:'20px'}} alt=""/><br/>
                            <span data-automation-id="help-sectiontext-block">
                                <a href="/m/refund" target="_blank" style={{color:'black'}}>
                                    Returns & Refunds
                                </a><br/>
                                <a href="/m/tnc" target="_blank" style={{color:'black'}}>
                                    Terms & Conditions
                                </a>
                            </span>
                    </li>
                    <li className="list-group-item">
                        
                            <img src={Policyicon} style={{width:'20px', height:'20px'}} alt=""/><br/>
                            <span data-automation-id="help-sectiontext-block">
                                <a href="/m/ppolicy" target="_blank" style={{color:'black'}}>
                                    Privacy Policy
                                </a><br/>                             
                            </span>
                    </li>

                    <li className="list-group-item">
                            <div> 
                                About       
                            </div>
                            
                            <span data-automation-id="help-sectiontext-block">
                            <div >    
                                <a  href="https://www.nutrinewron.com/natura " rel="noreferrer">www.nutrinewron.com/natura</a>    
                            </div>      
                            </span>
                     </li>                    
                </ul>
               </div>
          </div>
        )
    }
}

export default Sitefooter
