import React, {Component}from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchProduct } from '../../../action/productAction'
import { addToBag } from '../../../action/productAction'
import Utils, {Currency} from '../../../utils/utils'

// 1280352
const Price = (data) => {
    var returnHtml = ''
    if (data.price != null && data.price.amounts != null){
        var salePrice = data.price.amounts.find(element => element.type ==='SALE')
        var clearancePrice = data.price.amounts.find(element => element.type ==='CLEARANCE')
        var originalPrice = data.price.amounts.find(element => element.type ==='ORIGINAL')
        
        if(clearancePrice != null){
            returnHtml = <p><b>Clearance Price :</b><i> {Currency}{clearancePrice.max}</i><span style={{fontSize:'10px', fontWeight:'lighter'}}> ( {clearancePrice.maxPercentOff}% off )</span>
            <br/>
            <strike><b>Price :</b><i> {Currency}{originalPrice.max}</i></strike></p>
        }else if(salePrice != null){
            returnHtml = <p><b>Sale Price :</b><i> {Currency}{salePrice.max}</i><span style={{fontSize:'10px', fontWeight:'lighter'}}> ( {salePrice.maxPercentOff}% off )</span>
            <br/>
            <strike><b>Price :</b><i> {Currency}{originalPrice.max}</i></strike></p>
        }else{
            returnHtml = <p><b>Price :</b><i> {Currency}{originalPrice.max}</i><br/></p>
        }
    }
    return returnHtml;
  };

const ProductDescription = (data) => {
    var returnHtml='';
    if (data.desc != null){
        returnHtml = 
        <div className="row justify-content-md-center" style={{paddingBottom:'5px'}}>
            <div className="col col-lg-10" style={{textAlign:"left"}}>
                <b>Product Description : </b><span dangerouslySetInnerHTML={{ __html: data.desc }} />
            </div>
        </div>
    }
    return returnHtml;
}

const Quantity = () => {
    var optionHtml = [...Array(31).keys()].map((ele,k)=>{
        if(ele===0){
            return
        }
        return <option key={k} value={ele}>{ele}</option>
    })
    return <div style={{verticalAlign:'middle'}}>
        <label htmlFor="exampleFormControlSelect1" style={{textTransform: "capitalize"}}>Quantity : </label>
        <select className="form-control" id="exampleFormControlSelect1" style={{width:'30%', display:'inline'}} name="quantity">
            {optionHtml}
        </select>
    </div>
}

class Productpage extends Component {

    constructor(props){
        super(props)
        
        this.addToBag = this.addToBag.bind(this);
        this.state = { }
                   
    }

    componentDidMount = ()=> {
        this._asyncRequest = fetchProduct(this.props.location).then(
            externalData => {
              this._asyncRequest = null;
              //console.log(externalData.data)
              externalData.data['primaryImage'] = externalData.data.images.filter(e=>e.type==='PRIMARY')
              //extarct dim from product.
              var dim = new Utils().getDimension(externalData.data)
              externalData.data['dimension'] = dim;
              var count = 0 
              Object.keys(dim).forEach(element => {count = count + dim[element].length});
              externalData.data['showOptions'] = count > 1?true:false;
              externalData.data['selectedDimension'] = {};
              this.setState(externalData.data);
              this.loading = false;
            }
        );        
    }

    componentWillUnmount() {
        if (this._asyncRequest) {
          this._asyncRequest.cancel();
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState(nextProps.prod);
    }

    addToBag(e){
        e.preventDefault();
        const form = e.target;
        const data = new FormData(form);
        var quat = data.get('quantity')
        var sku_id = this.state['skus'][0]['id']
        this.props.addToBag(sku_id, quat)
    }
  
     render() {
         //dummy comment

        var isLoaded = Object.keys(this.state).length > 0
       
        if (!isLoaded)
            return (<div> loading....</div>);

       return(
           <div>
               <div className="row justify-content-md-center">
                    <div className="col col-lg-12">
                        <br />
                        
                        <br />
                    </div>
                </div>
                <div className="row justify-content-center" style={{paddingBottom:'5px'}}>
                    <div className="col col-lg-4 col-11" style={{borderColor:"black", border:"1px"}}>
                        <div className="row justify-content-center" style={{paddingBottom:'5px'}}>
                            <div className="col col-lg-11" style={{boxShadow: '2px 4px 8px 2px rgba(28,32,36,.2)'}}>
                                {this.state.primaryImage.map((e, i)=>{
                                    return <img src={'/prdimg/'+e.url} alt={e.altText} key={i} style={{width:'100%'}}/>
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-6 col-9" style={{textAlign:"left"}}>
                        <h4>{this.state.name}</h4>
                        <p>Product:<span style={{fontSize:"14px"}}> {this.state.id}</span></p>
                        <Price price={this.state.price}/>
                        <form onSubmit={this.addToBag}>
                            <Quantity/><br/>
                            <input className="btn btn-default" type='submit' name='Add_to_Bag' value="Add to Bag" style={{backgroundColor:'#cb9892'}}/>
                        </form>
                        
                        
                    </div>
                </div>
                <br/>
                <ProductDescription desc={this.state.description}/>
                <br/><br/>
           </div>
       )
     }
}

function mapStateToProps(state){
    return {prod:state.prod};
  }


export default connect(mapStateToProps,{addToBag})(withRouter(Productpage));
