import axios from "axios";
import LocalStorageService from "./localStorageService";

// LocalstorageService
const localStorageService = LocalStorageService.getService();

// Add a request interceptor
axios.interceptors.request.use(
   config => {
       const token = localStorageService.getAccessToken();
       if (token) {
           config.headers['Authorization'] = 'Bearer ' + token;
       } else if (config.url !== '/api/a/createToken' && 
                  config.url !== '/api/au/create' &&
                  config.url !== '/api/c/products' &&
                  config.url !== '/api/a/createAccountOrUpdatePassword' &&
                  config.url !== '/api/a/checkLogin' &&
                  config.url !==  '/api/o/cartTotal' &&
                  ! config.url.startsWith("/api/p/") &&
                  ! config.url.startsWith("/api/m/") &&
                  ! config.url.startsWith("/api/o/checkout/loadOrderConfirmation")
                  ){
            alert("Please login");
            window.location.href ='/';
        }    
       config.headers['Content-Type'] = 'application/json';
       return config;
   },
   error => {
       console.log(error)
       Promise.reject(error)
   });



//Add a response interceptor

axios.interceptors.response.use((response) => {
    // console.log('In the response Interceptors')
   return response
}, function (error) {
    // console.log('In the response error Interceptors')
   const originalRequest = error.config;

//    if (error.response.status === 401 && originalRequest.url === 
// 'http://13.232.130.60:8081/v1/auth/token) {
//        router.push('/login');
//        return Promise.reject(error);
//    }
   const refreshToken = localStorageService.getRefreshToken();
   if (error.response.status === 401)
   {
          console.log("interceptor found 401");
          if (!refreshToken)
          {
            console.log("There is no refersh token");
            //redirect to login page
            //
            return Promise.reject(error);
          }
   }

   if (error.response.status === 401 && !originalRequest._retry) {

       originalRequest._retry = true;
       
       return axios.post('/api/a/refresh',
           {
               "refresh_token": refreshToken
           })
           .then(res => {
               if (res.status === 201) {
                   localStorageService.setToken(res.data);
                   axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
                   return axios(originalRequest);
               }
           })
   }
   return Promise.reject(error);
});